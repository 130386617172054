




















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private cState: any;
  private window: any = window;
  private get user() {
    return this.$store.state.user || {};
  }
  private goRoute(path: any) {
    this.$router.push(path);
  }
  private goPatient() {
    this.$store.commit("updatePatient", null);
    this.$router.push("/main/workstation/patient-file");
  }
  /**
   * @description 点击切换账号
   */
  private handleCommand(command: any) {
    if (command === "退出登录") {
      this.$router.replace("/login");
      if (
        (window.location.href.indexOf("/main/workstation/patient-ganyu-edit") !=
          -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-ceping-edit"
          ) != -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-pinggu-edit"
          ) != -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-pinggu-report"
          ) != -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-zhenliao-edit"
          ) != -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-jianyan-edit"
          ) != -1 ||
          window.location.href.indexOf(
            "/main/workstation/patient-jiancha-edit"
          ) != -1) &&
        this.$store.state.ifCompose
      ) {
        return;
      }
      this.$store.commit("updateToken", "");
      this.$store.commit("updateUser", null);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } else if (command === "个人信息") {
      this.$store.commit("updateMyState", "个人资料");
      this.$router.push("/main/my/about");
    }
  }
}
