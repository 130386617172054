/**
 * @description 获取指标选项
 * @param type
 * @returns 
 */
const getZhibiaoOptions = (type: any): any => {
    let options: any = {}
    if (type == '危险因素') {
        options = {
            血压指标: [
                {
                    name: '收缩压'
                },
                {
                    name: '舒张压'
                },
            ],
            血糖指标: [
                {
                    name: '空腹血糖'
                },
                {
                    name: '糖化血红蛋白'
                },
            ],
            心衰指标: [
                {
                    name: 'NT-proBNP'
                },
            ],
            血脂指标: [
                {
                    name: '甘油三酯(TG)'
                },
                {
                    name: '胆固醇(CHO)'
                },
                {
                    name: '高浓度脂蛋白(HDL-C)'
                },
                {
                    name: '低浓度脂蛋白(LDL-C)'
                },
            ],
            血常规: [
                {
                    name: '血红蛋白'
                },
                {
                    name: '红细胞'
                },
                {
                    name: '白细胞'
                },
                {
                    name: '中性粒细胞'
                },
                {
                    name: '淋巴细胞'
                },
                {
                    name: '血小板'
                },
            ],
            肝功能: [
                {
                    name: '谷丙转氨酶(ALT)'
                },
                {
                    name: '谷草转氨酶(AST)'
                },
                {
                    name: '总胆红素(TBIL)'
                },
                {
                    name: '总蛋白(TP)'
                },
                {
                    name: '白蛋白(ALB)'
                },
                {
                    name: '甲胎蛋白(AFP)'
                },
            ],
            肾功能: [
                {
                    name: '肌酐(CREA)'
                },
                {
                    name: '尿素氮(BUN)'
                },
                {
                    name: '尿酸(URIC)'
                },
            ],
            其它: [
                {
                    name: '体重'
                },
                {
                    name: '体重指数(BMI)'
                },
                {
                    name: '体脂率'
                },
                {
                    name: '体脂肪'
                },
                {
                    name: '内脏脂肪'
                },
                {
                    name: '腰围'
                },
                {
                    name: '腰臀比'
                },
                {
                    name: '心率'
                },
                {
                    name: '血氧饱和度'
                },
                {
                    name: '脉搏'
                },
                {
                    name: '体温'
                },
            ],
        }

    } else if (type == '既往史') {
        options = {
            既往史: [
                {
                    name: '高血压'
                },
                {
                    name: '糖尿病'
                },
                {
                    name: '心脏病(心肌梗死、冠心病等）'
                },
                {
                    name: '骨质疏松症'
                },
                {
                    name: '支气管哮喘'
                },
                {
                    name: '精神疾病'
                },
                {
                    name: '高血脂症'
                },
                {
                    name: '肝脏病(脂肪肝、酒精肝炎、肝硬变等）'
                },
                {
                    name: '癌症'
                },
                {
                    name: '脑卒中'
                },
                {
                    name: '肥胖症'
                },
                {
                    name: '外伤史'
                },
                {
                    name: '无'
                },
            ]
        }
    } else if (type == '家族史') {
        options = {
            家族史: [
                {
                    name: '高血压'
                },
                {
                    name: '糖尿病'
                },
                {
                    name: '心脏病(心肌梗死、冠心病等）'
                },
                {
                    name: '骨质疏松症'
                },
                {
                    name: '支气管哮喘'
                },
                {
                    name: '精神疾病'
                },
                {
                    name: '高血脂症'
                },
                {
                    name: '肝脏病(脂肪肝、酒精肝炎、肝硬变等）'
                },
                {
                    name: '癌症'
                },
                {
                    name: '脑卒中'
                },
                {
                    name: '肥胖症'
                },
                {
                    name: '外伤史'
                },
                {
                    name: '无'
                },
            ]
        }
    } else if (type == '用药史') {
        options = {
            用药史: [
                {
                    name: '降压药'
                },
                {
                    name: '降脂药'
                },
                {
                    name: '降糖药'
                },
                {
                    name: '抗过敏药物'
                },
                {
                    name: '抗肿瘤药物'
                },
                {
                    name: '抗精神病药物'
                },
                {
                    name: '保健品'
                },
                {
                    name: '无'
                },
            ]
        }
    } else if (type == '过敏史') {
        options = {
            过敏史: [
                { name: '青霉素' },
                { name: '链霉素' },
                { name: '卡那霉素' },
                { name: '林可霉素' },
                { name: '头孢类' },
                { name: '磺胺类' },
                { name: '酒精' },
                { name: '花粉' },
                { name: '霉菌' },
                { name: '尘螨' },
                { name: '毛发皮屑' },
                { name: '牛奶' },
                { name: '鸡蛋' },
                { name: '大豆' },
                { name: '坚果' },
                { name: '无' },
            ]
        }
    } else if (type == '生活方式') {
        options = {
            现居住地: [{ name: '城市' }, { name: '农村' }],
            '现居住地区(以长江为届）': [{ name: '南方' }, { name: '北方' }],
            饮食偏好: [
                { name: '喜甘甜' },
                { name: '喜辛辣' },
                { name: '喜酸' },
                { name: '喜咸' },
                { name: '喜清谈' },
                { name: '喜油腻' },
                { name: '喜炙烤' },
                { name: '喜冷、喜凉' },
                { name: '喜热' },
                { name: '好饮茶' },
            ],
            饮食习惯: [
                { name: '常暴饮暴食' },
                { name: '常吃宵夜' },
                { name: '不吃或少吃早餐' },
                { name: '饮食不规律' },
                { name: '饮食正常' },
            ],
            饮酒: [
                { name: '不喝或偶尔喝酒' },
                { name: '经常喝酒' },
            ],
            吸烟: [
                { name: '不吸或偶尔吸烟' },
                { name: '经常吸烟' },
            ],
            睡眠习惯: [
                { name: '早睡早起' },
                { name: '晚睡晚起' },
                { name: '早睡晚起' },
                { name: '晚睡早起' },
                { name: '不规律' },
            ],
        }
    } else if (type == '心血管病史') {
        options = {
            心血管病史: [
                { name: '心脏骤停或心肌梗死或冠脉搭桥手术' },
                { name: '缺血性卒中或短暂性脑缺血发作' },
                { name: '急性冠脉综合征' },
                { name: '稳定性冠心病' },
                { name: '缺血性心肌病' },
                { name: '外周动脉疾病（除冠状动脉及主动脉以外的其他大动脉引起的动脉粥样硬化性病变，导致腿部、手臂或其他部位动脉血流减少）' },
                { name: '血管成形术或支架置入术' },
                { name: '冠状动脉造影或冠状动脉CT证实两支或以上主要冠状动脉狭窄>50%' },
                { name: '超声证实劲动脉狭窄>50%' },
                { name: '糖尿病≥10年或有靶器官损害（微量白蛋白尿、视网膜病变或肾病）' },
                { name: 'I型糖尿病患者病程>20年' },
                { name: '中重度慢性肾病（eGFR<60 ml/（min*1.73m*m））' },
                { name: '家族性高胆固醇血症' },
                { name: '总胆固醇>8.0mmol/L和（或）低密度脂蛋白（LDL-C）>4.9mmol/L' },
                { name: '血压≥180/110 mmHg' },
                { name: '泛血管疾病的病史' },
                { name: '我没有上述疾病或不知道' },
            ]
        }
    }
    return options;
}
/**
 * @description 二级导航名字处理（如果是四个字，则每行显示2个字）
 * @param name
 * @returns 
 */
const getNavLeftText = (name: any): any => {
    if (name.length === 4) {
        const arr = name.split("");
        return arr[0] + arr[1] + "\n" + arr[2] + arr[3];
    } else {
        return name;
    }
}
/**
 * @description 清除filter
 * @param data{}
 * @returns 
 */
const clearFilter = () => {
    localStorage.setItem("yichangListFilter", "");
    localStorage.setItem("shaichajianceFilter", "");
    localStorage.setItem("patientFileFilter", "");
    localStorage.setItem("manyiduFilter", "");
}
/**
 * @description 获取当前时间
 * @param format 时间格式
 * @returns 
 */
const getCurrentTime = (format: any) => {
    const now: any = new Date();
    const year: any = now.getFullYear(); // 得到年份
    let month: any = now.getMonth(); // 得到月份
    let date: any = now.getDate(); // 得到日期
    let hour: any = now.getHours(); // 得到小时
    let minu: any = now.getMinutes(); // 得到分钟
    let sec: any = now.getSeconds(); // 得到秒
    month = month + 1;
    if (month < 10) {
        month = "0" + month;
    }
    if (date < 10) {
        date = "0" + date;
    }
    if (hour < 10) {
        hour = "0" + hour;
    }
    if (minu < 10) {
        minu = "0" + minu;
    }
    if (sec < 10) {
        sec = "0" + sec;
    }
    let time = "";
    if (format == "yyyy") {
        time = year
    } else if (format == "yyyy-MM") {
        time = year + "-" + month
    } else if (format == "yyyy-MM-dd") {
        time = year + "-" + month + "-" + date
    } else if (format == "yyyy-MM-dd HH") {
        time = year + "-" + month + "-" + date + " " + hour
    } else if (format == "yyyy-MM-dd HH:mm") {
        time = year + "-" + month + "-" + date + " " + hour + ":" + minu
    } else if (format == "yyyy-MM-dd HH:mm:ss") {
        time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    }
    return time;
}
export {
    getZhibiaoOptions, getNavLeftText, clearFilter, getCurrentTime
};