



























































import { Component, Vue, Prop } from "vue-property-decorator";
import { clearFilter } from "@/utils/base";
@Component({})
export default class Name extends Vue {
  @Prop()
  private cState: any;
  private showYisheng: boolean = true;
  private showNeirong: boolean = true;
  private H: any = "";
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get href() {
    return window.location.href;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private goRouter(path: any) {
    // this.$store.commit("updatePatient", null);
    this.$store.commit("updatePatientFileFilter", {
      firstTab: "健康画像", //一级导航
      secondTab: "", // 二级导航
      thirdTab: "", // 三级导航,
      fourTab: "", // 四级导航,
      fourOpenId: "", // 三级导航是否打开字段
      isHideLeft: false, //是否隐藏左边患者列表
    });
    clearFilter();
    this.$router.push(path);
  }
  private iconfont(icon: any) {
    const reg: any = /(&#x)?(.*)/;
    let iconfontName: any;
    if (reg.test(icon)) {
      iconfontName = reg.exec(icon)[2];
    }
    return String.fromCharCode(parseInt(iconfontName, 16));
  }

  mounted() {
    this.H = document.body.clientHeight - 40;
  }
}
